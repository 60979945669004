div.tagsinput {
  border: 1px solid #CCC;
  background: #FFF;
  padding: 5px;
  width: 300px;
  height: 100px;
  overflow-y: auto
}

div.tagsinput span.tag {
  border: 1px solid #a5d24a;
  -moz-border-radius: 2px;
  -webkit-border-radius: 2px;
  display: block;
  float: left;
  padding: 5px;
  text-decoration: none;
  background: #cde69c;
  color: #638421;
  margin-right: 5px;
  margin-bottom: 5px;
  font-family: helvetica;
  font-size: 13px
}

div.tagsinput span.tag a {
  font-weight: 700;
  color: #82ad2b;
  text-decoration: none;
  font-size: 11px
}

div.tagsinput input {
  width: 80px;
  margin: 0 5px 5px 0;
  font-family: helvetica;
  font-size: 13px;
  border: 1px solid transparent;
  padding: 5px;
  background: 0 0;
  color: #000;
  outline: 0
}

div.tagsinput div {
  display: block;
  float: left
}

.tags_clear {
  clear: both;
  width: 100%;
  height: 0
}

.not_valid {
  background: #FBD8DB !important;
  color: #90111A !important
}
